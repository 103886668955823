// This is disabled but not removed to accommodate existing code referencing it

export default null

// Original code

/*
import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {})
export default jwt
*/
