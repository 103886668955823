<script>
/* eslint-disable global-require */
const srpLight = {
  appLogo: require('@/assets/images/logo/structrpro-logo.png'),
  companyLogo1: require('@/assets/images/logo/srp-logo-2.png'),
  companyLogo2: require('@/assets/images/logo/fvc-logo.png'),
}

const srpDark = {
  appLogo: require('@/assets/images/logo/structrpro-logo-white-green.png'),
  companyLogo1: require('@/assets/images/logo/srp-logo-green-white-2.png'),
  companyLogo2: require('@/assets/images/logo/fvc-logo-light.png'),
}

const seLight = {
  appLogo: '',
  companyLogo1: require('@/assets/images/logo/fvc-logo.png'),
  companyLogo2: '',
}

const seDark = {
  appLogo: '',
  companyLogo1: require('@/assets/images/logo/fvc-logo-light.png'),
  companyLogo2: '',
}

export default {
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
    srpBrand() {
      if (this.skin === 'light') {
        return srpLight
      }
      return srpDark
    },
    seBrand() {
      if (this.skin === 'light') {
        return seLight
      }
      return seDark
    },
  },
}
</script>
