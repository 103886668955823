export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Products',
    route: 'products',
    icon: 'GridIcon',
  },
  {
    title: 'Portfolios',
    route: 'portfolios',
    icon: 'ApertureIcon',
  },
  {
    title: 'Universe',
    route: 'universe-portfolio-report',
    icon: 'GlobeIcon',
  },
  {
    title: 'Data',
    icon: 'CloudIcon',
    children: [
      {
        title: 'Underlyings',
        route: 'data-underlyings',
        icon: 'BarChartIcon',
      },
      {
        title: 'Issuers',
        route: 'data-issuers',
        icon: 'TriangleIcon',
      },
    ],
  },
  {
    title: 'Upload',
    route: 'upload-spreadsheet',
    icon: 'UploadIcon',
  },
  // {
  //   title: 'Support',
  //   icon: 'HelpCircleIcon',
  //   children: [
  //     {
  //       title: 'Quick Start',
  //       route: 'support-quick-start',
  //       icon: 'MapIcon',
  //     },
  //     {
  //       title: 'Full Documentation',
  //       route: 'support-full-documentation',
  //       icon: 'FileTextIcon',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'support-knowledge-base',
  //       icon: 'BookOpenIcon',
  //     },
  //     {
  //       title: 'Terms & Conditions',
  //       route: 'support-terms-and-conditions',
  //       icon: 'ClipboardIcon',
  //     },
  //     {
  //       title: 'Privacy',
  //       route: 'support-privacy',
  //       icon: 'ShieldIcon',
  //     },
  //     {
  //       title: 'About',
  //       route: 'support-about',
  //       icon: 'InfoIcon',
  //     },
  //     {
  //       title: 'Contact Us',
  //       route: 'support-contact-us',
  //       icon: 'MailIcon',
  //     },
  //   ],
  // },
]
