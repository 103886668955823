<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-navbar-nav />
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <template v-if="brand === 'srp'">
        <b-nav-item
          class="d-none d-xl-inline mr-3"
          to="/"
        >
          <span class="mr-1">
            Powered by
          </span>
          <span class="brand-logo">
            <b-img
              :src="srpBrand.companyLogo1"
              alt="SRP"
            />
            <b-img
              v-if="srpBrand.companyLogo2 !==''"
              :src="srpBrand.companyLogo2"
              alt="FVC"
              class="ml-1"
            />
          </span>
        </b-nav-item>
      </template>

      <dark-Toggler class="d-none d-lg-block" />

      <b-nav-item-dropdown
        v-if="!$auth.loading"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $auth.user ? $auth.user.name : '' }}
            </p>
            {{ $auth.user ? $auth.user.subscription.ExternalAccountName : '' }}
          </div>
          <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          /> -->
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.p.prevent="$router.push({name: 'profile'})"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BImg, BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem, // BAvatar, BDropdownDivider,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import BrandLogosMixin from '@/mixins/BrandLogosMixin.vue'

export default {
  components: {
    BLink,
    BImg,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    // BAvatar,
    // BDropdownDivider,

    // Navbar Components
    DarkToggler,
  },
  mixins: [BrandLogosMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      brand: $themeConfig.app.brand,
      companyLogoImage1: $themeConfig.app.companyLogoImage1,
      companyLogoImage2: $themeConfig.app.companyLogoImage2,
    }
  },
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin + process.env.BASE_URL,
      })
    },
  },
}
</script>
